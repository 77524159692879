import * as React from "react";

const LiveIcon = (props) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask
      id="a"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={0}
      y={0}
      width={24}
      height={24}
    >
      <path fill="#C4C4C4" d="M0 0h24v24H0z" />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.532 4.236c.201 0 .398.067.567.195.164.134.3.308.399.51.098.203.155.428.167.66V16c.018.224-.021.45-.113.65a1.227 1.227 0 0 1-.654.569c-.27.095-.56.077-.82-.05L18 14.83c-.045 1.006-.421 1.954-1.052 2.654s-1.47 1.099-2.348 1.116H4.4a2.966 2.966 0 0 1-1.31-.277 3.343 3.343 0 0 1-1.112-.844 3.915 3.915 0 0 1-.735-1.275A4.331 4.331 0 0 1 1 14.7V6.9a4.33 4.33 0 0 1 .242-1.503c.167-.478.418-.911.735-1.275.317-.364.695-.651 1.112-.844a2.965 2.965 0 0 1 1.31-.277h10.2c.877.017 1.716.416 2.347 1.116.631.7 1.007 1.648 1.052 2.653l3.966-2.34a.938.938 0 0 1 .567-.194ZM6.792 13v-.643H4.895V9H4v4h2.792Zm1.465 0V9h-.892v4h.892Zm1.974 0h.937l1.512-4h-.996l-.984 3.045L9.718 9h-.99l1.503 4ZM16 13v-.643h-2.02v-1.101H15.7v-.627H13.98v-.983h2.016V9h-2.911v4H16Z"
        fill={props.fillcolor}
      />
    </g>
  </svg>
);

export default LiveIcon;
