import { CheckIcon, ClipboardIcon } from "@heroicons/react/outline";
import { Constants } from "@videosdk.live/react-sdk";
import React, { useState, useEffect } from "react";
import useResponsiveSize from "../hooks/useResponsiveSize";
import { meetingTypes } from "../utils/common";
import Select from "react-select";
import { getDataFromApi, postDataFromApi } from "../components/Services/CommonService";
import "../assets/custom.css";
import Dropdown from 'react-dropdown';
import { useParams, useNavigate } from "react-router-dom";

const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    // width: state.selectProps.width,
    // borderBottom: "1px dotted pink",
    borderColor: "grey",
    color: state.selectProps.menuColor,
    backgroundColor: "grey",
    color: "black",
    // color: "white",
    padding: 20,
  }),
};

export function MeetingDetailsScreen({
  participantName,
  setParticipantName,
  videoTrack,
  setVideoTrack,
  onClickStartMeeting,
  meetingType,
  setMeetingType,
  setMeetingMode,
  meetingMode,
  _handleOnCreateMeeting,
  onClickJoin,
  selectedLanguageId,
  setSelectedLanguageId,
  isGuestUser,
  guestUserMeetingId
}) {
  const p = useParams();
  console.log("p11===>", p);
  const [meetingId, setMeetingId] = useState("");
  const [meetingIdError, setMeetingIdError] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [iscreateMeetingClicked, setIscreateMeetingClicked] = useState(false);
  const [isJoinMeetingClicked, setIsJoinMeetingClicked] = useState(false);
  const [languagesFromApi, setLanguagesFromApi] = useState([]);
  const [options, setoptions] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // console.log("isGuestUser2", isGuestUser);
  // const [selectedLanguageId, setSelectedLanguageId] = useState("");
  // const [userName,setUserName]=useState("");
  const padding = useResponsiveSize({
    xl: 6,
    lg: 6,
    md: 6,
    sm: 4,
    xs: 1.5,
  });
  const selectType = [
    { label: "Meeting", value: meetingTypes.MEETING },
    // { label: "Interactive Live Streaming", value: meetingTypes.ILS },
  ];

  useEffect(() => {
    if (isJoinMeetingClicked) {
      if (videoTrack) {
        videoTrack.stop();
        setVideoTrack(null);
      }
      onClickStartMeeting();
    } else {
      if (meetingId.match("\\w{4}\\-\\w{4}\\-\\w{4}")) {
        onClickJoin(meetingId);
      } else setMeetingIdError(true);
    }
  }, [isJoinMeetingClicked]);

  const joinMeetingClickedFunction = async (e) => {
    // console.log("participantName===>", participantName);
    if (participantName.length >= 3 && !isGuestUser && selectedLanguage != "") {
      console.log("inside joinMeetingClickedFunction if")
      const meetingId = await _handleOnCreateMeeting();
      setMeetingId(meetingId);
      setIsJoinMeetingClicked(true);
      setIscreateMeetingClicked(true);
      if (meetingType === meetingTypes.ILS) {
        setMeetingMode(Constants.modes.CONFERENCE);
      }
    } else if (participantName.length >= 3 && isGuestUser) {
      console.log("inside joinMeetingClickedFunction else if")
      const meetingId = await _handleOnCreateMeeting();
      console.log('isGuestUser', isGuestUser)
      console.log('meetingId', meetingId)
      setMeetingId(meetingId);
      setIsJoinMeetingClicked(true);
      setIscreateMeetingClicked(true);
      if (meetingType === meetingTypes.ILS) {
        setMeetingMode(Constants.modes.CONFERENCE);
      }
    } else {
      if (participantName.length < 3) {
        alert("Please enter a valid name!");
      } else if (participantName.length >= 3 && !isGuestUser && selectedLanguage == "") {
        alert("Please enter a language!");
      } else {
        alert("Please provide inputs!");
      }
    }
  };
  //////////////////////////////////////////////////////////////////////////////
  // const joinMeetingClickedFunction = async (e) => {
  //   // console.log("participantName===>", participantName);
  //   if (participantName.length >= 3 && selectedLanguage != "") {
  //     const meetingId = await _handleOnCreateMeeting();
  //     setMeetingId(meetingId);
  //     setIsJoinMeetingClicked(true);
  //     setIscreateMeetingClicked(true);
  //     if (meetingType === meetingTypes.ILS) {
  //       setMeetingMode(Constants.modes.CONFERENCE);
  //     }
  //   } else {
  //     if (participantName.length < 3) {
  //       alert("Please enter a valid name!");
  //     } else if (participantName.length >= 3 && selectedLanguage == "") {
  //       alert("Please enter a language!");
  //     } else {
  //       alert("Please provide inputs!");
  //     }
  //   }
  // };
  //////////////////////////////////////////////////////////////////////////////

  const getlanguages = async () => {
    var query = "model=LanguageMasterData";
    // const response = await postDataFromApi("all/getAllMaster", query);
    const response = await getDataFromApi("languageListPerClient/" + p.clientid);
    if (response.data.code && response.data.data != null) {
      console.log("response.data", response.data.data)
      setLanguagesFromApi(response.data.data);
    }
  };

  function handleLanguageSelection(selectedOption) {
    console.log("handleLanguageSelection", selectedOption);
    setSelectedLanguage(selectedOption.label);
    setSelectedLanguageId(selectedOption.value);
  }

  useEffect(() => {
    languagesFromApi.map(function (content) {
      // if (content.availableInterprtercounter > 0) {
      return options.push({ value: content.languageId, label: content.language });
      // }
    });
  }, [languagesFromApi]);

  useEffect(() => {
    getlanguages();
  }, []);

  const handleSelection = (e) => {
    // console.log("handleSelection===>",e,e.label,e.value)
    setSelectedLanguage(e.label);
    setSelectedLanguageId(e.value);
  };

  return (
    <div
      className={`flex flex-1 flex-col w-full `}
      style={{
        padding: padding,
      }}
    >

      {!iscreateMeetingClicked && !isJoinMeetingClicked && (
        <div className="w-full md:mt-0 mt-4 flex flex-col" >
          <h1 className="text-white text-base">Enter your name</h1>

          <div className="flex items-center justify-center flex-col w-full mt-2">
            {meetingType === meetingTypes.ILS ? (
              <>
                <button
                  className="w-full bg-purple-350 text-white px-2 py-3 rounded-xl"
                  onClick={async (e) => {
                    const meetingId = await _handleOnCreateMeeting();
                    setMeetingId(meetingId);
                    setIscreateMeetingClicked(true);
                    if (meetingType === meetingTypes.ILS) {
                      setMeetingMode(Constants.modes.CONFERENCE);
                    }
                  }}
                >
                  Create a meeting
                </button>

                <button
                  className="w-full bg-purple-350 text-white px-2 py-3 mt-5 rounded-xl"
                  onClick={async (e) => {
                    setIsJoinMeetingClicked(true);
                    if (meetingType === meetingTypes.ILS) {
                      setMeetingMode(Constants.modes.CONFERENCE);
                    }
                  }}
                >
                  Join as a Host
                </button>
                <button
                  className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                  onClick={(e) => {
                    setIsJoinMeetingClicked(true);
                    if (meetingType === meetingTypes.ILS) {
                      setMeetingMode(Constants.modes.VIEWER);
                    }
                  }}
                >
                  Join as a Viewer
                </button>
              </>
            ) : (
              <>
                <input
                  type="text"
                  placeholder="Name"
                  // className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                  className={`w-full ${participantName.length < 3 ? "bg-gray-650" : "bg-purple-350"
                    }  text-white px-2 py-3 rounded-xl mt-5`}
                  onChange={(e) => setParticipantName(e.target.value)}
                  value={participantName}
                ></input>
                {!isGuestUser &&
                  <Dropdown
                    className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                    options={options}
                    onChange={(e) => handleSelection(e)}
                    // value={"Select a language"}
                    placeholder="Select a language"
                  />
                }

                <button
                  className="w-full bg-gray-650 text-white px-2 py-3 rounded-xl mt-5"
                  onClick={async (e) => {
                    // const meetingId = await _handleOnCreateMeeting();
                    // setMeetingId(meetingId);
                    // setIsJoinMeetingClicked(true);
                    // setIscreateMeetingClicked(true);
                    // if (meetingType === meetingTypes.ILS) {
                    //   setMeetingMode(Constants.modes.CONFERENCE);
                    // }
                    joinMeetingClickedFunction();

                    // setIsJoinMeetingClicked(true);
                    // if (meetingType === meetingTypes.ILS) {
                    //   setMeetingMode(Constants.modes.VIEWER);
                    // }
                  }}
                >
                  Join a meeting
                </button>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
