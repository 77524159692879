import React, { useEffect, useState, useRef } from "react";
// import Home from "./../HomeComponent/Home";
import Select from "react-select";
import {
  Typography,
  Paper,
  Grid,
  Box,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Modal,
  makeStyles,
} from "@material-ui/core";
import {
  Routes,
  Route,
  NavLink,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import Popup from "reactjs-popup";
import Side_2 from "../../assets/others/sidebar-2.svg";
import { ReactComponent as Icon_closegrey } from "../../assets/others/close-grey.svg";
import {
  postDataFromApi,
  getDataFromApi,
  putDataFromApi,
} from "../Services/CommonService";
import Interpreter_profile from "../../assets/others/interpreter-profile.svg";
import Multiple_userwhite from "../../assets/others/multiple-user-white.svg";
import Two_user from "../../assets/others/two_user.svg";
import Two_userwhite from "../../assets/others/two-user-white.svg";
import Connecting_interpreter_1 from "../../assets/others/connecting-interpreter-1.svg";
import Connecting_interpreter_2 from "../../assets/others/connecting-interpreter-2.svg";

import { ReactComponent as Iocn_Callback } from "../../assets/others/callback.svg";
import { ReactComponent as Iocn_Cancel } from "../../assets/others/cancel.svg";
import { ReactComponent as Iocn_Notconnect_interpreter } from "../../assets/others/notconnect-interpreter-girl.svg";
import { ReactComponent as Icon_crossbordercancel } from "../../assets/others/cross-border-cancel.svg";
import { ReactComponent as Iocn_Cancel_call } from "../../assets/others/cancel-call.svg";
import Connecting from "../../assets/others/connecting.svg";
import { ReactComponent as Icon_time } from "../../assets/others/time.svg";
import { getprofile, getAuth } from "../../components/Services/Helper";

import {
  getToken,
  createMeeting,
  sdkAPIKey,
  callSetup,
  startTimerToGetCallDetails,
  custoclearinterval,
  BaseUrl,
  CallStatusUpdateData,
} from "../../components/Services/videoskp";
import $ from "jquery";
import swal from "sweetalert";
import "../../assets/style.css";

const WaitingScreen = ({
  participantName,
  selectedLanguageId,
  isCallAccepted,
  callResponse,
  setBalanceSec,
  setBufferedSec,
  backToMainScreen,
}) => {
  const [languages, setlanguages] = useState([]);
  const [is_preccessed, setProccessed] = useState(true);
  const [is_notconnected, setNotconnected] = useState(false);
  const [languageId, setlanguageId] = useState("");
  const [formdata, setFormData] = useState({ language: "" });
  const [token, settoken] = useState([]);
  const [MeetingData, setMeetingData] = useState([]);
  const [MeetingId, setMeetingId] = useState("");
  const [CallResponseData, setCallResponseData] = useState({});
  const [intervalId, setIntervalId] = useState(false);
  const [isCallReceived, setisCallReceived] = useState(false);
  const [newIntervalId, setnewIntervalId] = useState("");
  const [isvideocallstarted, setisvideocallstarted] = useState(false);
  const [is_selected, setSelected] = useState(false);
  const [languageShow, setlanguageShow] = useState(false);
  const [isCallbackRequest, setisCallbackRequest] = useState("");
  const options = [];
  let iscallback = false;

  const _interval = useRef(null);
  const intervalFun = () => _interval.current;
  const clearCurrentInterval = () => {
    clearInterval(intervalFun());
  };
  const replaceCurrentInterval = (newInterval) => {
    clearCurrentInterval();
    _interval.current = newInterval;
  };

  useEffect(() => {
    var calltimeout = setTimeout(function () {
      if (!isCallReceived) {
        CallStatusUpdateData(
          callResponse.data._id,
          "7",
          "6",
          callResponse.data.initiator_id
        );
        clearCurrentInterval();
        setIntervalId(true);
        setNotconnected(true);
      }
    }, 45000);
    startTimer(callResponse);
    // setTimeout(() => {
    //   isCallAccepted()
    // }, 5000)
  }, []);

  const [open, setOpen] = useState(true);
  const closeModal = () => {
    setOpen(false);
  };

  const startTimer = async (callResponse) => {
    console.log("callResponse==>", callResponse);
    var count = 0;
    const singlenewIntervalId = setInterval(async function () {
      const response = await startTimerToGetCallDetails(
        callResponse.data._id,
        token
      );
      console.log("startTimer", response);
      count += 2;
      if (response && response.data !== "data not found") {
        console.log(newIntervalId);
        // response.data.status = 2
        if (response.data.status == 2) {
          setisCallReceived(true);
          clearInterval(newIntervalId);
          setIntervalId(true);

          //when call start
          const response = await CallStatusUpdateData(
            callResponse.data._id,
            "3",
            "3",
            callResponse.data.initiator_id
          );

          if (response && response.code) {
            // console.log("response inside response.data.status === 2======>", response);
            clearCurrentInterval();
            setBalanceSec(response.user_balance_call_seconds);
            setBufferedSec(response.user_buffer_seconds);
            isCallAccepted(
              response.user_balance_call_seconds,
              response.user_buffer_seconds
            );
          }
        } else if (response.data.status === 1) {
          // console.log("response inside response.data.status === 1======>", response);
          console.log("count", count);
          if (count === 16 || count === 30 || count === 46) {
            console.log("inside", count);
            checkIsCallConnected(callResponse.data._id);
          }
        }
      }
    }, 2000);
    replaceCurrentInterval(singlenewIntervalId);
  };
  // interpreter join code api
  const checkIsCallConnected = async (callSetupid) => {
    console.log("checkIsCallConnected", callSetupid);
    const response = await getDataFromApi(
      "sendNotificationRequestForInterpreterJoin/" + callSetupid
    );
    console.log("response==>", response);
    if (response && response.data.code === 0) {
      // alert("Sorry, There is no interpreter available.");
      // console.log("Sorry, There is no interpreter available.")
      swal({
        text: "Sorry, There is no interpreter available.",
        icon: "error",
      }).then(function () {
        // navigate("/home");
        clearCurrentInterval();
        clearInterval(newIntervalId);
        backToMainScreen();
      });

      // clearCurrentInterval();
      // clearInterval(newIntervalId);
    }
  };

  const CallStatusUpdate = async (buttonType) => {
    clearCurrentInterval();
    clearInterval(newIntervalId);
    setIntervalId(true);
    console.log("cancel button pressed");

    // ////////////////////////////
    // setNotconnected(false);
    // setNotconnected(false);
    // setProccessed(false);
    // setSelected(false);
    // // clearCurrentInterval();
    // backToMainScreen()
    // //////////////////////////

    if (buttonType == "cancel") {
      console.log("CallResponseData as", callResponse);
      const response = await CallStatusUpdateData(
        callResponse.data._id,
        "6",
        "6",
        callResponse.data.initiator_id
      );
      console.log("cancel", response);
      if (response && response.code) {
        setNotconnected(false);
        setNotconnected(false);
        setProccessed(false);
        setSelected(false);
        clearCurrentInterval();
        backToMainScreen();
      }
    } else if (buttonType == "callback") {
      const response = await CallStatusUpdateData(
        callResponse.data._id,
        "5",
        "6",
        callResponse.data.initiator_id
      );
      console.log(response);
    }
  };

  const callbackrequest = async () => {
    global.clearTimeout(isCallbackRequest);
    const response = await CallStatusUpdateData(
      callResponse.data._id,
      "5",
      "6",
      callResponse.data.initiator_id
    );
    backToMainScreen();
  };

  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      borderBottom: "1px dotted pink",
      color: state.selectProps.menuColor,
      padding: 20,
    }),

    control: (_, { selectProps: { width } }) => ({
      width: 200,
    }),
  };

  return (
    <>
      {!is_notconnected ? (
        <>
          {!is_preccessed ? (
            <>
              {/* <Home /> */}
              <Popup
                className="processingcall-modal language"
                modal
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
              >
                <div className="modal interpreter">
                  <button className="close" onClick={closeModal}>
                    <Icon_closegrey />
                  </button>
                </div>
              </Popup>
            </>
          ) : (
            <div
              className="page-main"
              style={{
                width: "100%",
                // height: "100%",
                // height: "200%",
                // display: "flex",
                // flex: 1,
                height: "100vh",
                backgroundColor: "black",
              }}
            >
              <div
                className="page-main-inner conference"
                style={{
                  width: "80%",
                  height: "80%",
                  margin: "auto",
                  marginTop: "5%",
                }}
              >
                <div className="processing-call-tab-content">
                  <div className="processing-call-tab-inner">
                    <div className="calling-names-dash">
                      <div className="inte-profile">
                        <img src={Interpreter_profile} />
                      </div>
                      <div className="inte-profile-details">
                        <span>
                          {/* {authdata.first_name + " " + authdata.last_name} */}
                          {participantName}
                        </span>
                        {/* <div>
			<span className="connecting_person">{authdata.length} <img src={Multiple_userwhite} /></span>
			<span className="connecting_person_names"><img src={Two_userwhite} /> {authdata.first_name}{authdata.length > 1 ? '+' : ''}{authdata.length > 1 ?  authdata.length - 1 : ''}</span>
			</div> */}
                      </div>
                    </div>
                    <div className="connect-interpreter">
                      <img
                        src={Connecting}
                        style={{ margin: "auto", marginBottom: "20px" }}
                      />
                      {/* {authdata.first_name ? authdata.first_name : 'an interpreter'}  have commneted for line 347 */}
                      <Typography>Connecting with an Interpreter</Typography>
                      <div className="connect-anim">
                        <div className="connect-anim-1">
                          <img
                            src={Connecting_interpreter_1}
                            className="Connecting_interpreter"
                          />
                        </div>
                        <div className="connect-anim-2">
                          <div className="dootedline">
                            <marquee direction="right" scrollamount="15">
                              <i className="fa fa-caret-right"></i>
                            </marquee>
                          </div>
                          <i className="fa fa-hourglass-2 fa-spin floating-icon"></i>
                        </div>
                        <div className="connect-anim-3">
                          <img
                            src={Connecting_interpreter_2}
                            className="Connecting_interpreter"
                          />
                        </div>
                      </div>
                      <div className="two_buttons">
                        {/* <a href={"http://localhost:3002/"}> */}
                        <Button
                          className="secondary"
                          variant="text"
                          onClick={() => CallStatusUpdate("cancel")}
                        >
                          <Iocn_Cancel />
                          Cancel
                        </Button>
                        {/* </a> */}
                        {/* <Popup
                          className="processingcall-modal"
                          trigger={
                            <Button className="primary" variant="text">
                              <Iocn_Callback />
                              Request Callback
                            </Button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal interpreter">
                              <div className="header">
                                {" "}
                                We will connect you to an interpreter soon.{" "}
                              </div>
                              <div className="content special-svg">
                                {" "}
                                <Icon_time />
                              </div>
                              <div className="actions">
                                <a href={"http://localhost:3002/"}>
                                  <Button
                                    className="primary"
                                    onClick={() => callbackrequest()}
                                  >
                                    Ok
                                  </Button>
                                </a>
                              </div>
                            </div>
                          )}
                        </Popup> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {isvideocallstarted ? (
            <Box className="full-screen">
              <div id="meeting-container-id"></div>
            </Box>
          ) : (
            <div
              className="page-main"
              style={{ width: "100%", height: "100%" }}
            >
              <div className="page-main-inner conference">
                <Box className="not_connect_call">
                  <div className="processing-call-tab-content">
                    <div className="processing-call-tab-inner">
                      <div className="calling-names-dash">
                        <div className="inte-profile">
                          <img src={Interpreter_profile} />
                        </div>
                        <div className="inte-profile-details">
                          <span>Dashing Members</span>
                          <div>
                            <span className="connecting_person">
                              <img src={Multiple_userwhite} />
                            </span>
                            <span className="connecting_person_names">
                              <img src={Two_userwhite} />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="connect-interpreter">
                        <Iocn_Cancel_call />
                        <Typography className="not_connect">
                          Couldn’t connect with an interpreter
                        </Typography>
                        <div className="svg_girl">
                          <Iocn_Notconnect_interpreter className="Connecting_interpreter" />
                          <Icon_crossbordercancel className="cross" />
                        </div>
                        <div className="two_buttons">
                          {/* <Popup
                            className="processingcall-modal"
                            trigger={
                              <Button className="primary" variant="text">
                                <Iocn_Callback />
                                Request Callback
                              </Button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal interpreter">
                                <div className="header">
                                  {" "}
                                  We will connect you to an interpreter soon.{" "}
                                </div>
                                <div className="content special-svg">
                                  {" "}
                                  <Icon_time />
                                </div>
                                <div className="actions">
                                  <Button
                                    className="primary"
                                    onClick={() => callbackrequest()}
                                  >
                                    Ok
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Popup> */}
                          <div
                            className="dismiss"
                            onClick={() => callbackrequest()}
                          >
                            Dismiss
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default WaitingScreen;
