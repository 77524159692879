import React, { useEffect, useMemo, useRef, useState } from "react";
import App from "./App";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import { redirect } from "react-router-dom";

const AppMain = () => {
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          {/* <Route exact path="/:company/:meetingId" element={<App />}></Route> */}
          <Route
            exact
            path="/:company/:clientid/:domainId"
            element={<App />}
          ></Route>
          <Route
            path="/:company/:clientid/:domainId/:callSetupId/:meetingId"
            element={<App />}
          ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
};

export default AppMain;
