import { mainURL } from './components/Services/CommonService'
const API_BASE_URL = "https://api.videosdk.live";
const VIDEOSDK_TOKEN = process.env.REACT_APP_VIDEOSDK_TOKEN;
const API_AUTH_URL = process.env.REACT_APP_AUTH_URL;
export const BaseUrl = mainURL

export const getToken = async () => {
  const res = await fetch(BaseUrl + "/get-token", {
    method: "GET",
  });
  const { token } = await res.json();
  console.log('token', token)
  return token;
};

export const createMeeting = async ({ token }) => {
  const url = BaseUrl + "/create-meeting";
  const options = {
    method: "POST",
    headers: { Authorization: token, "Content-Type": "application/json" },
    body: JSON.stringify({
      token: token
    })
  };

  const meetingResponse = await fetch(url, options)
    .then((response) => response.json())
    .catch((error) => console.error("error", error));

  return meetingResponse;
};

export const validateMeeting = async ({ roomId, token }) => {
  const url = `${API_BASE_URL}/v2/rooms/validate/${roomId}`;

  const options = {
    method: "GET",
    headers: { Authorization: token, "Content-Type": "application/json" },
  };

  const result = await fetch(url, options)
    .then((response) => response.json()) //result will have meeting id
    .catch((error) => console.error("error", error));

  return result ? result.roomId === roomId : false;
};
