import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Constants,
  MeetingProvider,
} from "@videosdk.live/react-sdk";
import { SnackbarProvider } from "notistack";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { useTheme } from "@material-ui/styles";
import { useMediaQuery } from "@material-ui/core";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { meetingTypes } from "./utils/common";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { ILSContainer } from "./interactive-live-streaming/ILSContainer";
import WaitingScreen from "./components/screens/WaitingScreen";
import WaitingToJoinScreen from "./components/screens/WaitingToJoinScreen";
import {
  callSetupForHelpline,
  CallStatusUpdateData,
} from "./components/Services/videoskp";
import { mainURL } from "./components/Services/CommonService";
import moment from "moment";
import FeedbackScreen from "./components/screens/FeedbackScreen";
import { useParams, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { Refresh } from "@material-ui/icons";
export const BaseUrl = mainURL;

const App = () => {
  const p = useParams();
  console.log("p===>", p);
  // console.log("p isNaN===>",p,isNaN(p.meetingId))

  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [meetingDetails, setMettingDetails] = useState({});
  const [selectedLanguageId, setSelectedLanguageId] = useState("");
  const [micOn, setMicOn] = useState(true);
  const [webcamOn, setWebcamOn] = useState(true);
  const [selectedMic, setSelectedMic] = useState({ id: null });
  const [selectedWebcam, setSelectedWebcam] = useState({ id: null });
  const [selectWebcamDeviceId, setSelectWebcamDeviceId] = useState(
    selectedWebcam.id
  );
  const [meetingType, setMeetingType] = useState(meetingTypes.MEETING);
  const [meetingMode, setMeetingMode] = useState(Constants.modes.CONFERENCE);
  const [selectMicDeviceId, setSelectMicDeviceId] = useState(selectedMic.id);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isWaitingStarted, setWaitingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);
  const [isBufferedUsed, setIsBufferedUsed] = useState(false);
  const [raisedHandsParticipants, setRaisedHandsParticipants] = useState([]);

  const [draftPolls, setDraftPolls] = useState([]);
  const [createdPolls, setCreatedPolls] = useState([]);
  const [endedPolls, setEndedPolls] = useState([]);
  const [downstreamUrl, setDownstreamUrl] = useState(null);
  const [afterMeetingJoinedHLSState, setAfterMeetingJoinedHLSState] =
    useState(null);
  const [CallResponseData, setCallResponseData] = useState({});
  const [balanceSec, setBalanceSec] = useState(0);
  const [bufferedSec, setBufferedSec] = useState(0);
  const [callStartedTime, setCallStartedTime] = useState(new Date());

  const [meetingIdProvided, setMeetingIdProvided] = useState(false);
  var navigate = useNavigate();

  const polls = useMemo(
    () =>
      createdPolls.map((poll) => ({
        ...poll,
        isActive:
          endedPolls.findIndex(({ pollId }) => pollId === poll.id) === -1,
      })),
    [createdPolls, endedPolls]
  );

  const useRaisedHandParticipants = () => {
    const raisedHandsParticipantsRef = useRef();

    const participantRaisedHand = (participantId) => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const newItem = { participantId, raisedHandOn: new Date().getTime() };

      const participantFound = raisedHandsParticipants.findIndex(
        ({ participantId: pID }) => pID === participantId
      );

      if (participantFound === -1) {
        raisedHandsParticipants.push(newItem);
      } else {
        raisedHandsParticipants[participantFound] = newItem;
      }

      setRaisedHandsParticipants(raisedHandsParticipants);
    };

    useEffect(() => {
      raisedHandsParticipantsRef.current = raisedHandsParticipants;
    }, [raisedHandsParticipants]);

    const _handleRemoveOld = () => {
      const raisedHandsParticipants = [...raisedHandsParticipantsRef.current];

      const now = new Date().getTime();

      const persisted = raisedHandsParticipants.filter(({ raisedHandOn }) => {
        return parseInt(raisedHandOn) + 15000 > parseInt(now);
      });

      if (raisedHandsParticipants.length !== persisted.length) {
        setRaisedHandsParticipants(persisted);
      }
    };

    useEffect(() => {
      const interval = setInterval(_handleRemoveOld, 1000);

      return () => {
        clearInterval(interval);
      };
    }, []);

    return { participantRaisedHand };
  };

  const theme = useTheme();
  const isXStoSM = useMediaQuery(theme.breakpoints.only("xs"));

  useEffect(() => {
    if (isXStoSM) {
      window.onbeforeunload = () => {
        return "Are you sure you want to exit?";
      };
    }
  }, [isXStoSM]);

  useEffect(() => {
    if (isMeetingLeft) {
      CallStatusUpdateData1();
    }
  }, [isMeetingLeft]);

  const settingCall = async () => {
    console.log("meetingDetails", meetingDetails);
    var inputData = JSON.stringify({
      // client_id: 23,
      client_id: p.clientid,
      language_id: selectedLanguageId,
      meetingDetails: meetingDetails,
      initiatorFullName: participantName,
    });
    console.log("inputData", inputData);
    const callResponse = await callSetupForHelpline(inputData);
    console.log("callresponse", callResponse);
    setCallResponseData(callResponse);
    console.log("callResponse callSetupForHelpline", callResponse);

    if (callResponse && callResponse.code == 1) {
      setWaitingStarted(true);
    } else if (callResponse.code == 0) {
      // alert(callResponse.message);
      swal({
        // text: callResponse.message,
        text: "Can't make call now. Please contact admin.",
        icon: "error",
      }).then(function () {
        navigate(`/${p.company}`);
        window.location.reload();
        // navigate(-1)
      });
    } else if (callResponse.code == 3) {
      alert(callResponse.message);
    } else {
      alert(callResponse.message);
    }
  };

  const CallStatusUpdateData1 = async () => {
    const calllStartDateTime = moment(callStartedTime);
    const endDate = moment(new Date(new Date()));
    var bufferedSecondsDuration = 0;
    if (isBufferedUsed) {
      const startDate = moment(callStartedTime);
      bufferedSecondsDuration = endDate.diff(startDate, "seconds");
      console.log("bufferedSecondsDuration", bufferedSecondsDuration);
    }
    var callSecondsDuration = endDate.diff(calllStartDateTime, "seconds");
    console.log("callSecondsDuration", callSecondsDuration);

    var inputData = JSON.stringify({
      call_setup_id: CallResponseData.data._id,
      user_type: "0",
      signable_user_type: "1",
      user_id: CallResponseData.data.initiator_id,
      is_call_end: "1",
      call_end_type: "4",
      call_status: "4",
      call_attendee_status: "6",
      call_second_used: callSecondsDuration,
      is_buffer_used: isBufferedUsed ? 1 : 0,
      used_buffer_second: bufferedSecondsDuration,
    });
    console.log("inputData_statusChanged", inputData);
    try {
      const response = await fetch(BaseUrl + `/add/CallStatusUpdate`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: inputData,
      })
        .then(async (result) => {
          const res = await result.json();
          // var response=res.parse()
          console.log("hii-->", res[0]);
          console.log("hii-->", res);
          // console.log("hii response-->", response);
          // console.log("hii-->", res.data);
          // console.log("hii-->", res.data.code);
          // if(res.code==0){
          //   swal({
          //     text: res.message,
          //     icon: "error",
          //   }).then(function () {
          //     // navigate('/home')
          //   });
          // }
          return res;
        })
        .catch((error) => console.log("error", error));
      // console.log("CallStatusUpdateDataresponse======>",response)
    } catch (e) {
      console.log(e);
    }
  };

  const _interval = useRef(null);
  const intervalFunBalance = () => _interval.current;
  const clearCurrentIntervalBalance = () => {
    clearInterval(intervalFunBalance());
  };
  const replaceCurrentIntervalBalance = (newInterval) => {
    clearCurrentIntervalBalance();
    _interval.current = newInterval;
  };

  const _intervalBuffer = useRef(null);
  const intervalFunBuffer = () => _intervalBuffer.current;
  const clearCurrentIntervalBuffer = () => {
    clearInterval(intervalFunBuffer());
  };
  const replaceCurrentIntervalBuffer = (newInterval) => {
    clearCurrentIntervalBuffer();
    _intervalBuffer.current = newInterval;
  };

  const startBalanceMinutesTimer = (balSec, bufSec) => {
    console.log("balSec==>", balSec);
    console.log("bufSec==>", bufSec);
    const minutes = Math.floor(balSec / 60);
    const seconds = Math.floor(balSec - minutes * 60);
    var time = `${minutes < 10 ? `0${minutes}` : minutes}
             :${seconds < 10 ? `0${seconds}` : seconds}`;
    console.log("balance_time", time);
    const interval_ID = setInterval(() => {
      var current_time = time;
      //console.log('current_time', current_time)
      var minutes = 0;
      var seconds = 0;
      var timeParts = current_time.split(":");
      minutes = parseInt(timeParts[0]);
      seconds = parseInt(timeParts[1]);

      if (seconds === 0 && minutes === 0) {
        console.log("buffer munites started");
        clearCurrentIntervalBalance();
        setIsBufferedUsed(true);
        //bufferStartedTime = new Date()
        startBufferMinutesTimer(bufSec);
      } else {
        seconds = seconds - 1;

        if (minutes === 0) {
          minutes = 0;
        } else if (seconds <= 0) {
          minutes = minutes - 1;
          seconds = 59;
        }
        if (minutes <= 0) {
          minutes = 0;
        }

        var displyMinutes = minutes.toString();
        var displaySeconds = seconds.toString();
        if (minutes < 10) {
          displyMinutes = "0" + minutes.toString();
        }
        if (seconds < 10) {
          displaySeconds = "0" + seconds.toString();
        }

        time = displyMinutes + ":" + displaySeconds;
      }
    }, 1000);

    replaceCurrentIntervalBalance(interval_ID);
  };

  const startBufferMinutesTimer = (bufSec) => {
    const minutes = Math.floor(bufSec / 60);
    const seconds = Math.floor(bufSec - minutes * 60);
    var time = `${minutes < 10 ? `0${minutes}` : minutes}
             :${seconds < 10 ? `0${seconds}` : seconds}`;
    console.log("time", time);
    const interval_ID = setInterval(() => {
      var current_time = time;
      console.log("buffer_time", current_time);
      var minutes = 0;
      var seconds = 0;
      var timeParts = current_time.split(":");
      minutes = parseInt(timeParts[0]);
      seconds = parseInt(timeParts[1]);

      if (seconds === 0 && minutes === 0) {
        clearCurrentIntervalBuffer();
        //leave();
        CallStatusUpdateData1();
      } else {
        seconds = seconds - 1;

        if (minutes === 0) {
          minutes = 0;
        } else if (seconds <= 0) {
          minutes = minutes - 1;
          seconds = 59;
        }
        if (minutes <= 0) {
          minutes = 0;
        }

        var displyMinutes = minutes.toString();
        var displaySeconds = seconds.toString();
        if (minutes < 10) {
          displyMinutes = "0" + minutes.toString();
        }
        if (seconds < 10) {
          displaySeconds = "0" + seconds.toString();
        }

        time = displyMinutes + ":" + displaySeconds;
      }
    }, 1000);

    replaceCurrentIntervalBuffer(interval_ID);
  };

  useEffect(() => {
    if (p.meetingId) {
      setMeetingIdProvided(true);
    }
  }, []);

  function RefreshScreenFunction() {
    window.location.reload();
  }

  const addGuestUser = async () => {
    try {
      var contactDetailsArray = [{ name: participantName, contact_number: "" }];
      var inputData = JSON.stringify({
        call_setup_id: p.callSetupId,
        contactDetails: contactDetailsArray,
      });
      console.log("inputData==>", inputData)
      const response = await fetch(BaseUrl + `/add/CallSetupAttendee`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: inputData,
      })
        .then(async (result) => {
          const res = await result.json();
          console.log("res==>", res)
          return res;
        })
        .catch((error) => console.log("error", error));
      console.log("response==>", response)
      if (response) {
        await CallStatusUpdateData(
          response.data.call_setup_id,
          "3",
          "3",
          response.data.user_id
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {isMeetingStarted ? (
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          autoHideDuration={5000}
          maxSnack={3}
        >
          <MeetingProvider
            config={{
              meetingId,
              micEnabled: micOn,
              webcamEnabled: webcamOn,
              name: participantName ? participantName : "TestUser",
              mode: meetingMode,
              multiStream: meetingType === meetingTypes.MEETING ? true : false,
            }}
            token={token}
            reinitialiseMeetingOnConfigChange={true}
            joinWithoutUserInteraction={true}
          >
            {meetingType === meetingTypes.MEETING ? (
              <MeetingContainer
                onMeetingLeave={() => {
                  setToken("");
                  setMeetingId("");
                  setWebcamOn(false);
                  setMicOn(false);
                  setMeetingStarted(false);
                }}
                setIsMeetingLeft={setIsMeetingLeft}
                selectedMic={selectedMic}
                selectedWebcam={selectedWebcam}
                selectWebcamDeviceId={selectWebcamDeviceId}
                setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                selectMicDeviceId={selectMicDeviceId}
                setSelectMicDeviceId={setSelectMicDeviceId}
                useRaisedHandParticipants={useRaisedHandParticipants}
                raisedHandsParticipants={raisedHandsParticipants}
                micEnabled={micOn}
                webcamEnabled={webcamOn}
                participantName={participantName}
                selectedLanguageId={selectedLanguageId}
                meetingIdProvided={meetingIdProvided}
                callSetupId={p.meetingId ? p.meetingId : CallResponseData.data._id}
              />
            ) : (
              <ILSContainer
                onMeetingLeave={() => {
                  setToken("");
                  setMeetingId("");
                  setWebcamOn(false);
                  setMicOn(false);
                  setMeetingStarted(false);
                }}
                setIsMeetingLeft={setIsMeetingLeft}
                selectedMic={selectedMic}
                selectedWebcam={selectedWebcam}
                selectWebcamDeviceId={selectWebcamDeviceId}
                setSelectWebcamDeviceId={setSelectWebcamDeviceId}
                selectMicDeviceId={selectMicDeviceId}
                setSelectMicDeviceId={setSelectMicDeviceId}
                useRaisedHandParticipants={useRaisedHandParticipants}
                raisedHandsParticipants={raisedHandsParticipants}
                micEnabled={micOn}
                webcamEnabled={webcamOn}
                meetingMode={meetingMode}
                setMeetingMode={setMeetingMode}
                polls={polls}
                draftPolls={draftPolls}
                setDraftPolls={setDraftPolls}
                setCreatedPolls={setCreatedPolls}
                setEndedPolls={setEndedPolls}
                downstreamUrl={downstreamUrl}
                setDownstreamUrl={setDownstreamUrl}
                afterMeetingJoinedHLSState={afterMeetingJoinedHLSState}
                setAfterMeetingJoinedHLSState={setAfterMeetingJoinedHLSState}
              />
            )}
          </MeetingProvider>
        </SnackbarProvider>
      ) : isMeetingLeft ? (
        //&& p.meetingId === undefined
        // <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
        <FeedbackScreen
          call_setup_id={CallResponseData.data._id}
          user_id={CallResponseData.data.initiator_id}
          clientid={p.clientid}
          company={p.company}
          domainId={p.domainId}
          backToMainScreen={() => {
            setWaitingStarted(false);
            setMeetingStarted(false);
            setIsMeetingLeft(false);
            RefreshScreenFunction();
          }}
        />
      ) : isWaitingStarted ? (
        <WaitingScreen
          participantName={participantName}
          selectedLanguageId={selectedLanguageId}
          isCallAccepted={(balSec, bufSec) => {
            setMeetingStarted(true);
            setWaitingStarted(false);
            let currentDateTime = new Date();
            setCallStartedTime(currentDateTime);
            startBalanceMinutesTimer(balSec, bufSec);
          }}
          backToMainScreen={() => {
            setWaitingStarted(false);
            setMeetingStarted(false);
            setIsMeetingLeft(false);
            RefreshScreenFunction();
          }}
          callResponse={CallResponseData}
          setBalanceSec={setBalanceSec}
          setBufferedSec={setBufferedSec}
        />
      ) : (
        < JoiningScreen
          participantName={participantName}
          setParticipantName={setParticipantName}
          setMettingDetails={setMettingDetails}
          setMeetingId={setMeetingId}
          setToken={setToken}
          setMicOn={setMicOn}
          micEnabled={micOn}
          webcamEnabled={webcamOn}
          setSelectedMic={setSelectedMic}
          setSelectedWebcam={setSelectedWebcam}
          setWebcamOn={setWebcamOn}
          onClickStartMeeting={() => {
            console.log("p.meetingId", p.meetingId);
            if (p.meetingId) {
              console.log("inside if loop");
              console.log("p.meetingId==>", p.meetingId);
              setMeetingStarted(true);
              setWaitingStarted(false);
              addGuestUser();
            } else {
              console.log("inside else");
              settingCall();
            }
            // setWaitingStarted(true)
            //setMeetingStarted(true);
          }}
          isGuestUser={p.meetingId ? true : false}
          // isGuestUser={p.callSetupId ? false : true}
          guestUserMeetingId={p.meetingId ? p.meetingId : ""}
          startMeeting={isMeetingStarted}
          setIsMeetingLeft={setIsMeetingLeft}
          meetingType={meetingType}
          setMeetingType={setMeetingType}
          meetingMode={meetingMode}
          setMeetingMode={setMeetingMode}
          selectedLanguageId={selectedLanguageId}
          setSelectedLanguageId={setSelectedLanguageId}
        />
      )}
    </>
  );
};

export default App;
